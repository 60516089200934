.about-page {
  padding: 5rem 0;

  h1, .blurb {
    text-align: center;
    margin-bottom: 3rem;
  }

  .blurb {
    padding: 0 2rem;
  }

  p {
    margin-bottom: 2rem;

    .desc {
      text-align: justify;
    }
  }

  .container {
    padding: 0 3rem;
  }

  .hours {
    padding-top: 1rem;
  }
}