.contact-page {
  padding: 100px 0;

  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.7) 75%, RGBA(0, 0, 0, 0.61)),
    url(../../img/bamboo-bg-2.jpg) no-repeat center top;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;

  h1 {
    font-size: 40px;
    color: white;
  }

  p {
    color: white;
  }

  .heading {
    margin-bottom: 5rem;
  }
  .form-group {
    margin-bottom: 25px;

    input {
      .form-control {
        height: auto;
      }
    }

    textarea {
      .form-cotrol {
        height: 248px;
      }
    }

    input,
    textarea {
      padding: 20px;
    }
  }

  .form-control {
    border-color: #528026;
    box-shadow: none;
  }

  .button-area {
    margin-top: 3rem;
  }
}

@media only screen and (min-device-width: 768px) {
  .contact-page {
    h1 {
      font-size: 65px;
    }
  }
}
