.masthead {
  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.7) 75%, RGBA(0, 0, 0, 0.61)),
    url(../../../img/bamboo-bg-3.jpg) no-repeat center top;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 15rem 0;

  h1 {
    font-size: 55px;
  }

  p {
    font-size: 25px;
  }

  h1, p {
    color: #fcfcfc;
  }
}

@media only screen and (min-device-width: 768px) {
  .masthead {
    h1 {
      font-size: 65px;
    }

    p {
      font-size: 35px;
    }
  }
}
