.btn {
  box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.1) !important;
  padding: 1.25rem 2rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  border: 0;

}

.disabled {
  background-color: gray !important;
  cursor: default;
}

.btn-primary {
  background-color: #528026;

  &:hover {
    background-color: #446a20;
  }

  &:focus {
    background-color: #446a20;
    color: white;
  }

  &:active {
    background-color: #5d912b !important;
  }
}