.menu-item {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  text-align: center;

  .card {
    min-height: 300px;

    border: none;

    .card-text {
      color: black;
      // margin-bottom: 1rem;
    }

    &.combo {
      border: 2px solid #528026;
    }

    // img {
    //   width: 250px;
    //   height: 250px;
    // }

    hr {
      border-color: #528026;
      border-width: 0.25rem;
      width: 3rem;
    }
  }
}
