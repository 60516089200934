.contact-section {
  padding: 5rem 0 0;
  background-color: #161616;

  .card {
    border: 0;
    border-bottom: 0.25rem solid #528026;

    h4 {
      font-size: 0.8rem;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 0.15rem;
    }

    hr {
      border-color: #528026;
      border-width: 0.25rem;
      width: 3rem;
    }
    
  }

  .social {
    margin-top: 5rem;

    a {
      text-align: center;
      height: 3rem;
      width: 3rem;
      line-height: 3rem;
      color: rgba(255, 255, 255, 0.3);
    }

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }

    &:active {
      color: #fff;
    }
  }
}