.menu-section {
  .category-section {
    margin-bottom: 2rem;
    .pic-container {
      padding: 15px;
    }
  }

  .menu-items {
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #528026;

    .item {
      span {
        font-size: 12px;
      }
    }

    .item-price {
      text-align: right;
    }
  }
}

@media only screen and (min-device-width: 768px) {
  .menu-section {
    .menu-items {
      .item {
        span {
          font-size: 18px;
        }
      }
    }

    h2 {
      font-size: 2rem;
    }
  }
}
