.App {
  min-height: 100vh;
}

body {
  font-size: 15px;
  line-height: 1.52;
  color: #717f86;
  font-size: 16px;
  font-weight: 400;
  overflow-x: hidden;
  letter-spacing: 0.0625em;
}
body,
h1,
h2,
h3 {
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Vidaloka', serif; */
}

footer {
  padding: 5rem 0;
}
