.thanksgiving {
  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.7) 75%, RGBA(0, 0, 0, 0.61)),
      url(../../../img/bg-thanksgiving-1.jpg) no-repeat center top;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  padding: 100px 0;

  p {
    margin-bottom: 2rem;

    span {
      color: red;
    }
  }
}

@media only screen and (min-device-width: 768px) {
  .thanksgiving {
    .thanksgiving-container {
      width: 50%;
    }
  }
}
